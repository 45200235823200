import react, { useState, useEffect } from "react";
import { BsCheckLg, BsArrowLeft } from "react-icons/bs";
import { VscCircleFilled } from "react-icons/vsc";
import { useScrollDirection } from "react-use-scroll-direction";
import Container from "./styled";
import Marcador from "../marcador";
import NumberFormat from "react-number-format";
import { Fade } from "react-awesome-reveal";
import { Link } from "react-scroll";
import { useBack } from "use-back";
import { useLocation, useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import url from "../../../utils/url";
import axios from "axios";
import Plan from "./plan";
// Custom Hooks
import { useScrollTop } from "../../../hooks/useScrollTop";
import { useQuestion } from "../../../hooks/useQuestion";


export default function Elegi({ nextStep, planSelected, planes, cotizacion }) {


  // Custom Hooks
  const { isQuestionOpen, handleToggle } = useQuestion()
  useScrollTop()

  // Scroll Direction
  const { isScrollingUp, isScrollingDown } = useScrollDirection();

  const [scrollea, setScrollea] = useState(false);

  const handleScroll = () => {
    if (isScrollingUp) {
      setScrollea(true);
    } else if (isScrollingDown) {
      setScrollea(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  useEffect(() => {
    const scriptPixel = document.createElement("script");
    const scriptGoogleAds = document.createElement("script");

    scriptPixel.text = `fbq('track', 'Lead', {})`;
    scriptGoogleAds.text = `gtag('event', 'conversion', {'send_to': 'AW-959485109/uy9TCIC7nFcQtanCyQM'})`;

    document.body.appendChild(scriptPixel);
    document.head.appendChild(scriptGoogleAds);

    return () => {
      document.body.removeChild(scriptPixel);
      document.head.removeChild(scriptGoogleAds);
    };
  }, []);

  /*
  useEffect(() => {
    if (state && state.bicicleteria) {
      updateBicicleteria(state.bicicleteria);
    }
  }, [state]);

  const getDataFromDB = async (id_operacion, hash) => {
    try {
      const getDataOperacion = await axios.get(
        `${url}/api/operaciones/tablaOperaciones/${id_operacion}`
      );
      localStorage.setItem(
        "segurobici-vehiculo",
        getDataOperacion.data[0].producto
      );
      localStorage.setItem(
        "segurobici-sumaasegurada",
        getDataOperacion.data[0].valor_asegurado
      );
      localStorage.setItem(
        "segurobici-provincia",
        getDataOperacion.data[0].provincia
      );
      localStorage.setItem(
        "segurobici-nombre",
        getDataOperacion.data[0].nombre
      );
      localStorage.setItem("segurobici-email", getDataOperacion.data[0].email);

      const getData = await axios.get(`${url}/api/operaciones/${id_operacion}`);
      const planesToSend = {
        planes: getData.data,
      };

      planesToSend.planes.forEach((plan) => {
        plan.precio = "$" + plan.precio;
      });

      setData(planesToSend);
    } catch (error) {
      console.log(error);
    }
  };
  */

  // Back Button
  const { hasBack, handleBack } = useBack();

  // Ordenar para que prudencia este primero 
  const sortedData = planes.slice().sort((a, b) => {
    if (a.nombre_compania === 'Prudencia' && b.nombre_compania !== 'Prudencia') {
      return -1; // a va primero
    }
    if (a.nombre_compania !== 'Prudencia' && b.nombre_compania === 'Prudencia') {
      return 1; // b va primero
    }
    return 0; // no cambiar el orden si ambos son iguales
  });

  return (
    <Container
      scroll={scrollea == true ? "arriba" : scrollea == false && "abajo"}
    >
      {/* Marcador */}
      <Marcador
        pasoUno="hecho"
        pasoDos="haciendo"
        pasoTres=""
        pasosTerminados=""
        titulo="¡Cotizaste tu seguro con éxito!"
      ></Marcador>
      {/* Botón Atras Container */}
      <div className="boton-atras-container opacidad03">
        {/* Botón Atras */}
        <button type="button" onClick={handleBack}>
          <span>
            <BsArrowLeft />
          </span>
          Volvé a cotizar
        </button>
      </div>
      {/* Valor Auto */}
      <div className="valor-vehiculo-container">
        {/* Texto */}
        <span>
          {localStorage.getItem("segurobici-vehiculo") == "bicicleta electrica"
            ? "El valor de tu bicicleta eléctrica es de: "
            : localStorage.getItem("segurobici-vehiculo") ==
              "monopatin electrico"
            ? "El valor de tu monopatín eléctrico es de: "
            : "El valor de tu " +
              localStorage.getItem("segurobici-vehiculo") +
              " es de: "}
          <NumberFormat
            // Aca despues hay que cambiarlo por el valor desde localStorage
            value={localStorage.getItem("segurobici-sumaasegurada")}
            displayType={"text"}
            thousandSeparator={true}
            prefix={"$"}
            renderText={(value, props) => <p {...props}>{value}</p>}
          />
        </span>
      </div>
      {/* Referencias Container */}
      {/* <div className="referencias-container opacidad03">
        <div className="referencias">
          <p>Destrucción Total</p>
          <p>Robo Parcial</p>
          <p>Robo Total</p> 
          <p>Responsabilidad Civil</p>
          <p>Competencias</p>
          <p>Accidentes Personales</p>
          <p>Asistencia</p>
        </div>
      </div> */}
      <h3 className="centrarTitulo" >¡Conocé nuestras coberturas y encontrá la solución perfecta para vos!</h3>
      <div className="dropdowns-container">
        {/* Render de cada plan */}
        {sortedData.map((plan, index) => (
            <Fade
              delay={100}
              duration={600}
              triggerOnce={true}
              className={
                plan.nombre_compania === 'Prudencia'
                  ?  "dropdown-recomendado dropdown-container"
                  :  "dropdown-mejor-precio"
              }
            >
              <Plan
                data={plan}
                index={index}
                active={isQuestionOpen}
                handleToggle={handleToggle}
                planSelected={planSelected}
                nextStep={nextStep}
                cotizacion={cotizacion}
                landing={cotizacion.tipo}
              />
            </Fade>
          ))}
        {/* Coberturßa uso laboral  */}
        {localStorage.getItem("segurobici-vehiculo") == "bicicleta" && (
          <Fade
            delay={100}
            duration={600}
            triggerOnce={true}
            className="cobertura-laboral-container"
          >
            <p id="cobertura-laboral">
              <span id="texto-laboral">
                Estas coberturas son únicamente para uso particular.
              </span>{" "}
              <span id="pregunta-laboral">¿Trabajas con tu bici?</span>{" "}
              <a href="/seguro-de-bicicleta-comercial">
                Cotizá una cobertura especial para uso comercial
              </a>
            </p>
          </Fade>
        )}
      </div>
    </Container>
  );
}
